import "../css/Card.css";

function Card(props) {
    return(
        <div className="card">
            <h4 className="card-title"><a className="card-link" href={props.link} rel="noreferrer" target="_blank">{props.title}</a></h4>
            <p className="card-parag">{props.parag}</p>
        </div>
    );
}
export default Card;