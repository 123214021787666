import './Blog.css';
import Card from "./components/Card";
import githubLogo from "./assets/GitHub-Mark-Light-64px.png"

function Blog() {
  return (
    <div className="App">
        <div className="App-left"/>
        <div className="App-center">
            <h1>Hi, I'm Giorgio</h1>
            <h3 className="App-center-subTitle">CS Student, Programmer and Music Enthusiast</h3>
            <p className="App-center-subTitleEmail"><a className="App-center-emailLink" href="mailto:giorgio@proietti.me">giorgio@proietti.me</a></p>
            <h3 className="App-center-subTitle App-link"><a href="https://github.com/Shoenen" rel="noreferrer" target="_blank"><img src={githubLogo} alt="GitHub Logo"/></a></h3>
            <h2>Stuff I've done</h2>
            <div className="App-center-stuffDone">
                <Card className="App-center-stuffDone-item" title="Premio l'albatros" link="https://premiolalbatros.org/" parag={'Website for the non-profit literary festival "Premio l\'albatros"'}/>
                <Card className="App-center-stuffDone-item" title="UniBotJS" link="https://github.com/appinfosapienza/UniBotJS" parag="Co-Main Developer of a discord bot used to help students of my course find useful information about lessons and professors"/>
            </div>
            <h2><br/>Boring Stuff</h2>
            <p className="App-center-parag">
                I'm currently working as a Software Developer at HCL Software. Mainly using React with Typescript & Javascript, Java and various libraries.<br/>
                My main programming languages are Java, Typescript & Javascript, Python and C#. <br/>
                I've also used some frameworks such as JavaFX and MAUI.<br/><br/>
                Currently I'm specialized in Frontend development, but i also like to do Backend and DevOps.
            </p>
            <h2><br/>Music</h2>
            <p className="App-center-parag">
                I'm a drummer and I really love music, I mean, <strong>really.</strong><br/>
                I mainly listen to Rock and Metal, with all their subgenres. I'm also a huge fan of Japanese Rock/Metal stuff. However I listen to pretty much every genre, except Trap, I don't like it at all :|<br/>
            </p>
        </div>
        <div className="App-right"/>
    </div>
  );
}

export default Blog;
